import { useMemo, useState } from 'react';
import { Block } from 'types/page';
import { ReactComponent as PencilIcon } from 'Assets/pencil.svg';
import BlockSettings from 'BlockSettings/BlockSettings';
import RequiredFieldWrapper from 'Components/Common/RequiredFieldWrapper/RequiredFieldWrapper';
import ToolbarManipulatorInput from '../../../Common/ToolbarManipulatorInput/ToolbarManipulatorInput';
import ToolbarManipulatorPopup from '../../../Common/ToolbarManipulatorPopup/ToolbarManipulatorPopup';
import ToolbarManipulatorDropdown from '../../../Common/ToolbarManipulatorDropdown/ToolbarManipulatorDropdown';

import styles from './DestinationQuickAccessAction.module.scss';

interface IProps {
  block: Block;
  onToggleManipulatorVisibility: (value: boolean) => void;
}

const DestinationQuickAccessAction = ({
  block,
  onToggleManipulatorVisibility,
}: IProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleValueChange = (
    attributeId: string,
    value: string | number | boolean
  ) => {
    block.onAttributeChanged(attributeId, value);
    block[attributeId].value = value;
    block.view.overlay.update();
    onToggleManipulatorVisibility(true);
  };

  const destinationLabel = useMemo(() => {
    const option = block.destination.options.find(
      (opt: { label: string; value: string }) =>
        opt.value === block.destination.value
    );

    return option ? option.label : '';
  }, [block.destination.options, block.destination.value]);

  const requiredAttributes = block.attributes
    .filter((attr: any) => attr.visible)
    .map((attr: any) => {
      let component;

      switch (attr.type) {
        case 'String':
          component = (
            <ToolbarManipulatorInput
              value={attr.value}
              placeholder={attr.placeholder}
              onChange={(e) => handleValueChange(attr.id, e.target.value)}
            />
          );
          break;
        case 'Dropdown':
          component = (
            <ToolbarManipulatorDropdown
              label={attr.displayName}
              value={attr.value}
              options={attr.options}
              onChange={(value: string) => handleValueChange(attr.id, value)}
            />
          );
          break;
        default:
          break;
      }

      return { value: attr.value, component };
    });

  return (
    <>
      <div
        className={styles.destinationLabel}
        onClick={() => setIsModalVisible(true)}
      >
        {destinationLabel}
        <PencilIcon className={styles.editIcon} />
        <ToolbarManipulatorPopup
          className={styles.popup}
          isPopupVisible={isModalVisible}
          onPopupClose={() => setIsModalVisible(false)}
          popupContent={
            <BlockSettings block={block} onValueChange={handleValueChange} />
          }
        />
      </div>

      {requiredAttributes.map(
        (attr: any) =>
          attr && (
            <RequiredFieldWrapper
              missingValue={!attr.value}
              className={styles.requiredFieldWrapper}
            >
              {attr.component}
            </RequiredFieldWrapper>
          )
      )}
    </>
  );
};

export default DestinationQuickAccessAction;
