export const GET_FUNNEL_PRODUCTS_BY_PAGE_ID = {
  operationName: 'getFunnelProductsByPageId',
  query: `query getFunnelProductsByPageId(
      $pageId: String!
    ) {
      getFunnelProductsByPageId(
        pageId: $pageId
      ) {
        name
        descriptor
        price
        currency
        isSubscription
        isSevenDayTrial
      }
    }`,
};