import { LegacyRef, useEffect, useRef, useState } from 'react';
import { Block } from 'types/page';
import RerollComponent from 'Components/AIRerollImage/RerollComponent';
import ToolbarManipulatorPopup from '../../Common/ToolbarManipulatorPopup/ToolbarManipulatorPopup';
import ToolbarManipulatorButton from '../../Common/ToolbarManipulatorButton/ToolbarManipulatorButton';

import styles from './AIImageReroll.module.scss';

interface IProps {
  block: Block;
  enabled: boolean;
}

const AIImageReroll = ({ block, enabled }: IProps) => {
  const [showInput, setShowInput] = useState(false);

  const originalRef = useRef(null);
  const popupRef: LegacyRef<HTMLDivElement> = useRef(null);

  const handleChangeImage = (url: string) => {
    if (block.image) {
      block.image.value = url;
      block.view.update();
    }
  };

  const saveOriginalImage = () => {
    if (!block.image) {
      return;
    }
    originalRef.current = block.image.value;
  };

  const restoreOriginalImage = () => {
    setShowInput(false);
    if (!block.image || !originalRef.current) {
      return;
    }
    block.image.value = originalRef.current;
    originalRef.current = null;
    block.view.update();
  };

  const handleSubmitImage = (url: string) => {
    originalRef.current = url as any;
    setShowInput(false);
  };

  useEffect(() => {
    if (!showInput) return;

    const handleResize = () => {
      if (!popupRef.current) return;

      if (window.innerWidth <= 500) {
        popupRef.current.style.right = '-430px';
      } else {
        popupRef.current.style.right = 'unset';
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [showInput]);

  return (
    <ToolbarManipulatorButton
      className={styles.button}
      onClick={() => setShowInput(true)}
      enabled={enabled}
    >
      AI
      <ToolbarManipulatorPopup
        isPopupVisible={showInput}
        onPopupClose={restoreOriginalImage}
        className={styles.rerollModal}
        popupContent={
          <RerollComponent
            onChange={handleChangeImage}
            onStart={saveOriginalImage}
            onCancel={restoreOriginalImage}
            onSubmit={handleSubmitImage}
          />
        }
      />
    </ToolbarManipulatorButton>
  );
};

export default AIImageReroll;
